<template>
	<div class="data-content">
		<div class="top-box">
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">状态</label>
				<el-select class="search-input" size="small" v-model="publishFlag" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option label="已发布" value="1"></el-option>
					<el-option label="待发布" value="0"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="courseList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				border
				style="width: 100%">
				<el-table-column
					width="50"
					label="NO.">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="code"
					width="150"
					show-overflow-tooltip
					label="课程编号">
					<template slot-scope="scope">
						<span class="line1" @click="handleQuestionList(scope.row.id,scope.row.name)">{{scope.row.code}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="课程名称">
					<template slot-scope="scope">
						<span class="line1" @click="handleQuestionList(scope.row.id,scope.row.name)">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="courseTypeNames"
					show-overflow-tooltip
					label="课程类型">
				</el-table-column>
				<el-table-column
					prop="rangeName"
					width="80"
					show-overflow-tooltip
					label="教育分类">
					<template slot-scope="scope">
						<span>远程</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="examinTime"
					width="80"
					show-overflow-tooltip
					label="考试时间">
				</el-table-column>
				<el-table-column
					prop="passScore"
					show-overflow-tooltip
					width="80"
					label="及格分数">
				</el-table-column>
				<el-table-column
					prop="examinAmount"
					show-overflow-tooltip
					width="80"
					label="随机题目">
				</el-table-column>
				<el-table-column
					prop="examinLimiteTimes"
					show-overflow-tooltip
					width="100"
					label="可考试次数">
				</el-table-column>
				<el-table-column
					label="状态"
					align="center"
					width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.publishFlag == 1">已发布</span>
						<span v-if="scope.row.publishFlag == 0 || scope.row.publishFlag == 2">待发布</span>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			eduType: '',//1线上2线下
			publishFlag: '',//1发布2撤销
			courseList: [],
			deviceType: '',
			deviceTypeList: [],
			visible: false,
			errorList: []
		}
	},
	created() {
		this.pageNo = 1;
		this.searchKey = '';

		const searchKey = window.sessionStorage.getItem('query:training:question:searchKey')
		window.sessionStorage.removeItem('query:training:question:searchKey')
		if (searchKey) this.searchKey = searchKey

		const publishFlag = window.sessionStorage.getItem('query:training:question:publishFlag')
		window.sessionStorage.removeItem('query:training:question:publishFlag')
		if (publishFlag || publishFlag == 0) this.publishFlag = publishFlag

		this.getCourseList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.publishFlag = '';
			this.pageNo = 1;
			this.getCourseList();
		},
		// 获取课程数据
		getCourseList() {
			let that = this;
			that.$request.post(
				"courseInfoMaintain",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					publishFlag: that.publishFlag,
					eduType: '1'
				},
				function (r) {
					if (r.code == "0") {
						that.courseList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 去往详情页
		handleQuestionList(courseId,name) {
			if (this.searchKey) window.sessionStorage.setItem('query:training:question:searchKey', this.searchKey)
			if (this.publishFlag !== '') window.sessionStorage.setItem('query:training:question:publishFlag', this.publishFlag)

			this.$router.push({
				name: 'questionList',
				query: {
					courseId: courseId,
					name: name
				}
			})
		},
		handleSearch() {
			this.pageNo = 1;
			this.getCourseList();
		},
		handleSizeChange(val) {
			this.getCourseList();
		},
		handleCurrentChange(val) {
			this.getCourseList();
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
.line1{
	color: blue;
	cursor: pointer;
}
</style>


